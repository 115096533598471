export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const homeGames = {
  roulette: [
    {
      href: "/casino/ezgevo-american-roulette",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/AmericanRoulette.webp",
      alt: "american roulette",
      name: "American Roulette",
    },
    {
      href: "/casino/ezgevo-auto-roulette",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/AutoRoulette.webp",
      alt: "auto roulette",
      name: "Auto Roulette",
    },
    {
      href: "/casino/ezg-casino-marina-roulette-1",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/CasinoMarinaRoulette1.webp",
      alt: "casino marina roulette 1",
      name: "Casino Marina Roulette 1",
    },
    {
      href: "/casino/ezg-casino-marina-roulette-2",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/CasinoMarinaRoulette2.webp",
      alt: "casino marina roulette 2",
      name: "Casino Marina Roulette 2",
    },
    {
      href: "/casino/ezg-cricket-auto-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/CricketAutoRoulette.webp",
      alt: "cricket auto roulette",
      name: "Cricket Auto Roulette",
    },
    {
      href: "/casino/ezg-ruleta-del-sol",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/RuletaDelSol.webp",
      alt: "ruleta del sol",
      name: "Ruleta Del Sol",
    },
    {
      href: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/DiamondRoulette.webp",
      alt: "diamond roulette",
      name: "Diamond Roulette",
    },
    {
      href: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/DoubleBallRoulette.webp",
      alt: "double ball roulette",
      name: "Double Ball Roulette",
    },
    {
      href: "/casino/ezgevo-first-person-roulette",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/FirstPersonRoulette.webp",
      alt: "first person roulette",
      name: "First Person Roulette",
    },
    {
      href: "/casino/ezg-italian-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/ItalianRoulette.webp",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    {
      href: "/casino/ezgevo-lightning-roulette",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/LightningRoulette.webp",
      alt: "lightning roulette",
      name: "Lightning Roulette",
    },
    {
      href: "/casino/ezg-namaste-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/NamasteRoulette.webp",
      alt: "namaste roulette",
      name: "Namaste Roulette",
    },
    {
      href: "/casino/ezg-oracle-360-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/Oracle360Roulette.webp",
      alt: "oracle 360 roulette",
      name: "Oracle 360 Roulette",
    },
    {
      href: "/casino/ezg-prestige-auto-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/PrestigeAutoRoulette.webp",
      alt: "prestige auto roulette",
      name: "Prestige Auto Roulette",
    },
    {
      href: "/casino/ezg-skyline-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/SkylineRoulette.webp",
      alt: "skyline roulette",
      name: "Skyline Roulette",
    },
    {
      href: "/casino/ezg-speed-auto-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/SpeedAutoRoulette.webp",
      alt: "speed auto roulette",
      name: "Speed Auto Roulette",
    },
    {
      href: "/casino/ezg-speed-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/SpeedRoulette.webp",
      alt: "speed roulette",
      name: "Speed Roulette",
    },
    {
      href: "/casino/ezg-turkish-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/TurkishRoulette.webp",
      alt: "turkish roulette",
      name: "Turkish Roulette",
    },
    {
      href: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/roulette/home/UltimateRoulette.webp",
      alt: "ultimate roulette",
      name: "Ultimate Roulette",
    },
  ],
  andarbahar: [
    {
      href: "/casino/ezg-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/andarbahar/home/AndarBahar.webp",
      alt: "andar bahar",
      name: "Andar Bahar",
    },
    {
      href: "/casino/ezg-casino-marina-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/andarbahar/home/OTTAndarBahar.webp",
      alt: "ott andar bahar",
      name: "OTT Andar Bahar",
    },
    {
      href: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/andarbahar/home/UltimateAndarBahar.webp",
      alt: "ultimate andar bahar",
      name: "Ultimate Andar Bahar",
    },
  ],
  teenpatti: [
    {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/poker/home/BetOnTeenPatti.webp",
      alt: "bet on teen patti",
      name: "Bet On Teen Patti",
    },
    {
      href: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/poker/home/OneDayTeenPatti.webp",
      alt: "one day teen patti",
      name: "One Day Teen Patti",
    },
    {
      href: "/casino/ezg-teen-patti-3-card",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/poker/home/TeenPatti3Card.webp",
      alt: "teen patti 3 card",
      name: "Teen Patti 3 Card",
    },
  ],
  baccarat: [
    {
      href: "/casino/ezg-baccarat",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/baccarat.webp",
      alt: "baccarat",
      name: "Baccarat",
    },
    {
      href: "/casino/ezgevo-baccarat-control-squeeze",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/BaccaratControlSqueeze.webp",
      alt: "baccarat control squeeze",
      name: "Baccarat Control Squeeze",
    },
    {
      href: "/casino/ezg-knockout-baccarat",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/KnockoutBaccarat.webp",
      alt: "knockout baccarat",
      name: "Knockout Baccarat",
    },
    {
      href: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/CasinoMarinaBaccarat1.webp",
      alt: "casino marina baccarat 1",
      name: "Casino Marina Baccarat 1",
    },
    {
      href: "/casino/ezg-casino-marina-baccarat-2",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/CasinoMarinaBaccarat2.webp",
      alt: "casino marina baccarat 2",
      name: "Casino Marina Baccarat 2",
    },
    {
      href: "/casino/ezg-cricket-war",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/CricketWar.webp",
      alt: "cricket war",
      name: "Cricket War",
    },
    {
      href: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/DragonTiger.webp",
      alt: "dragon tiger",
      name: "Dragon Tiger",
    },
    {
      href: "/casino/ezg-fortune-baccarat",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/FortuneBaccarat.webp",
      alt: "fortune baccarat",
      name: "Fortune Baccarat",
    },
    {
      href: "/casino/ezg-no-commission-baccarat",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/NoCommissionBaccarat.webp",
      alt: "no commission baccarat",
      name: "No Commission Baccarat",
    },
    {
      href: "/casino/ezgevo-speed-baccarat-a",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/SpeedBaccaratA.webp",
      alt: "speed baccarat a",
      name: "Speed Baccarat A",
    },
    {
      href: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/baccarat/home/Super6Baccarat.webp",
      alt: "super 6 baccarat",
      name: "Super 6 Baccarat",
    },
  ],
  lucky7: [
    {
      href: "/casino/ezg-lucky-7",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/lucky7/home/Lucky7.webp",
      alt: "lucky 7",
      name: "Lucky 7",
    },
  ],
  dice: [
    {
      href: "/casino/ezgevo-lightning-dice",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/dice/home/LightningDice.webp",
      alt: "lightning dice",
      name: "Lightning Dice",
    },
    {
      href: "/casino/ezg-sic-bo",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/dice/home/SicBo.webp",
      alt: "sic bo",
      name: "Sic Bo",
    },
    {
      href: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/dice/home/SuperSicBo.webp",
      alt: "super sic bo",
      name: "Super Sic Bo",
    },
    {
      href: "/casino/ezg-ultimate-sic-bo",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/dice/home/UltimateSicBo.webp",
      alt: "ultimate sic Ubo",
      name: "Ultimate Sic Bo",
    },
  ],
  cards32: [
    {
      href: "/casino/ezg-32-cards",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/card/home/32Cards.webp",
      alt: "32 cards",
      name: "32 Cards",
    },
  ],
  blackjack: [
    {
      href: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/BlackjackParty.webp",
      alt: "blackjack party",
      name: "Blackjack Party",
    },
    {
      href: "/casino/ezgevo-classic-speed-blackjack-10",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/ClassicSpeedBlackjack10.webp",
      alt: "classic speed blackjack 10",
      name: "Classic Speed Blackjack 10",
    },
    {
      href: "/casino/ezg-vip-diamond-blackjack",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/DiamondVIPBlackjack.webp",
      alt: "vip diamond blackjack",
      name: "VIP Diamond Blackjack",
    },
    {
      href: "/casino/ezgevo-free-bet-blackjack-1",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/FreeBetBlackjack1.webp",
      alt: "free bet blackjack 1",
      name: "Free Bet Blackjack 1",
    },
    {
      href: "/casino/ezg-gold-blackjack-3",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/GoldBlackjack3.webp",
      alt: "gold blackjack 3",
      name: "Gold Blackjack 3",
    },
    {
      href: "/casino/ezg-gold-blackjack-5",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/GoldBlackjack5.webp",
      alt: "gold blackjack 5",
      name: "Gold Blackjack 5",
    },
    {
      href: "/casino/ezg-salon-prive-blackjack",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/SalonePriveBlackjack.webp",
      alt: "salon prive blackjack",
      name: "Salon Prive Blackjack",
    },
    {
      href: "/casino/ezg-unlimited-blackjack",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/UnlimitedBlackjack.webp",
      alt: "unlimited blackjack",
      name: "Unlimited Blackjack",
    },
    {
      href: "/casino/ezg-vip-surrender-blackjack",
      code: "",
      casino: "Ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/blackjack/home/VIPSurrenderBlackjackwith.webp",
      alt: "vip surrender blackjack",
      name: "VIP Surrender Blackjack",
    },
  ],
  gameshows: [
    {
      href: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/gameshows/home/CashOrCrash.webp",
      alt: "cash or crash",
      name: "Cash Or Crash",
    },
    {
      href: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/gameshows/home/CrazyTime.webp",
      alt: "crazy time",
      name: "Crazy Time",
    },
    {
      href: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/gameshows/home/DreamCatcher.webp",
      alt: "dream catcher",
      name: "Dream Catcher",
    },
    {
      href: "/casino/ezgevo-football-studio",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/gameshows/home/FootballStudio.webp",
      alt: "football studio",
      name: "Football Studio",
    },
    {
      href: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "Evolution",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/gameshows/home/MegaBall.webp",
      alt: "mega ball",
      name: "Mega Ball",
    },
    // {
    //   href: "/casino/ezgevo-side-bet-city",
    //   code: "",
    //   casino: "Evolution",
    //   provider: "Ezugi",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/gameshows/home/SideBetCity.webp",
    //   alt: "side bet city",
    //   name: "Side Bet City",
    // },
  ],
  // newgames: [
  //   {
  //     href: "/casino/qtechturbogames-aero",
  //     code: "",
  //     casino: "Ezugi",
  //     provider: "qtech",
  //     homeUrl: pageUrlGenerator("/home"),
  //     imgUrl: "./images/newgames/Aero.png",
  //     alt: "Aero",
  //     name: "Aero",
  //   },
  //   {
  //     href: "/casino/qtechaviatrix-aviatrix",
  //     code: "",
  //     casino: "Ezugi",
  //     provider: "qtech",
  //     homeUrl: pageUrlGenerator("/home"),
  //     imgUrl: "./images/newgames/aviatrix.png",
  //     alt: "Aviatorx",
  //     name: "Aviatorx",
  //   },
  // ],
};
